@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: PressStart;
    src: url('font/PressStart2P-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
@font-face {
  font-family: Pangolin;
  src: url('font/Pangolin-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-light;
  src: url('font/Montserrat-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
  
@font-face {
  font-family: Montserrat-medium;
  src: url('font/Montserrat-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
  
@font-face {
  font-family: Montserrat-regular;
  src: url('font/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Montserrat-semibold;
  src: url('font/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body:not(.footer) { -webkit-touch-callout: none !important; }
a:not(.footer) { -webkit-user-select: none !important; }

.game {
  font-family: "Montserrat-medium";
  background-color: rgb(100, 100, 100);
  color: rgb(233, 233, 233)
}

/* .footer {
  background-color: #e2e8f0 !important;
  color: rgb(100, 100, 100);
} */

/* 
.fix-button {
  background-color: rgb(233, 233, 233);
  color: red;
  
} */
.iteminvisible {
    flex: 0 1 auto;
    width: 25vw;
    /* height: 100px; */
    visibility: hidden;
  }
  
.row-count, .col-count, .bomb-count {
  transform: rotate(90deg);
  background-color: transparent;
}

.sliders {
  transform: rotate(-90deg);
  display: flex;
  width: 95%;
  position: absolute;
  flex-direction: row;
  align-items: center;
  top: 350%;
  background-color: transparent;
}

input[type=range]
{
  -webkit-appearance: none;
  height: 7px;
  width: 16vw;
  /* position: relative; */
  background-color: rgba(red, green, blue, 0.4);
  border-radius: 5px;
  border: 2px;
  border-color: rgb(148 163 184);
  border-style: solid;
  outline: none;
  /* transform: rotate(-90deg);  */
}

input[type="range"]::-webkit-slider-thumb{
  -webkit-appearance: none;
  background-color: rgb(226 232 240);
  height: 5em;
  width: 2em;
  border-radius: 10%;
}

.burger-menu-container, .burger-menu-container-open {
  transform: translateY(5vh);
  cursor: pointer;
  position: relative;
  height: 1200%;
  width: 150%;
  background-color: rgb(188,188,188);
  border-radius: 5%;
}
.burger-menu {
  width: 3.5vw;
  height: 0.8vh;
  position: relative;
  top: 45%;
  left: 16%;
  background: white;
  border-radius : 5px;
  box-shadow: 0 2px 5px rgba(118, 118, 118, .5);
  /* transform: translateX(50px); */
  transition: all .4s ease-in-out;
}

.burger-menu::before, 
.burger-menu::after {
  content: "";
  position: absolute;
  width: 3.5vw;
  height: 0.8vh;
  background: white;
  border-radius : 5px;
  box-shadow: 0 2px 5px rgba(118, 118, 118, .5);
  transition: all .4s ease-in-out;

}

.burger-menu::before {
  transform: translateY(-16px);
}
.burger-menu::after {
  transform: translateY(16px);
}

.burger-menu-container-open .burger-menu {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}

.burger-menu-container-open .burger-menu::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.burger-menu-container-open .burger-menu::after {
  transform: rotate(-45deg) translate(35px, 35px);
}
